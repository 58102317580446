<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
      :label="`${$t('COMMON.RESELLER')} (*)`"
      :placeholder="$t('COMMON.RESELLER')"
    >
      <reseller-selector
        :reseller="organization.reseller.id"
        :filterable="true"
        :showAll="false"
        @resellerChanged="
          (resellerId) => {
            organization.reseller.id = resellerId;
            organization.owner.id = null;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.reseller" />

    <image-selector
      :defaultImage="organization.logo"
      ressource_name="organizations"
      :ressource_id="organization.id ? organization.id : 0"
      field="logo"
      @imageChanged="
        (file_url) => {
          organization.logo = file_url;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.attachment" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="organization.name"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.name" />

    <base-input
      type="textarea"
      :label="$t('COMMON.EXCERPT')"
      :placeholder="$t('COMMON.EXCERPT')"
    >
      <html-editor v-model="organization.excerpt" @change="onFormChanged()">
      </html-editor>
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <base-input
      :label="`${$t('COMMON.EMAIL')} (*)`"
      :placeholder="$t('COMMON.EMAIL')"
      v-model="organization.email"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.email" />

    <!-- Phone number -->
    <div class="row">
      <div class="col-2">
        <base-input :label="`${$t('COMMON.TYPE')}`">
          <el-select
            :label="$t('COMMON.TYPE')"
            :placeholder="$t('COMMON.TYPE')"
            v-model="organization.phone_type"
            @change="
              (type) => {
                organization.phone_type = type;
                onFormChanged();
              }
            "
          >
            <el-option
              v-for="(value, key) in phoneTypesOptions"
              :key="key"
              :value="value"
              :label="$t(`COMMON.PHONE_TYPE_${value}`)"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-7">
        <base-input :label="`${$t('COMMON.PHONE')} (*)`">
          <phone-number-input
            :phoneNumber="organization.phone"
            @phoneNumberChanged="
              (phone) => {
                organization.phone = phone;
                onFormChanged();
              }
            "
            :required="true"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.phone" />
      </div>
      <div class="col-3">
        <base-input
          :label="`${$t('COMMON.EXTENSION')}`"
          v-model="organization.phone_extension"
          @change="
            () => {
              onFormChanged();
            }
          "
          :inputClasses="'extension-input'"
        />
        <validation-error :errors="apiValidationErrors.phone_extension" />
      </div>
    </div>

    <!-- Other phone numbers -->
    <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
      <phone-numbers-selector
        :phoneNumbers="organization.other_phones"
        @phoneNumbersChanged="
          (phoneNumbers) => {
            organization.other_phones = phoneNumbers;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.other_phones" />

    <base-input
      :label="`${$t('COMMON.ADDRESS')} (*)`"
      :placeholder="$t('COMMON.ADDRESS')"
      v-model="organization.address"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.address" />

    <taxes-selector
      :label="$t('COMMON.DEFAULT_TAXES')"
      :taxes="organization.taxes"
      @taxesChanged="
        (taxes) => {
          organization.taxes = taxes;
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.taxes" />

    <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>

    <billing-informations-form
      :billingInformationsData="organization"
      :formErrors="formErrors"
      @onChangeBillingInformations="billingInformationsChanged"
    />

    <div class="my-4">
      <div class="row flex justify-content-between">
        <div class="col-auto">
          <base-button
            type="button"
            class="btn btn-sm btn-primary"
            native-type="submit"
            :disabled="loading"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading" />
            {{
              organization.id
                ? $t("ORGANIZATIONS.EDIT_ORGANIZATION")
                : $t("ORGANIZATIONS.ADD_ORGANIZATION")
            }}
          </base-button>
        </div>

        <div class="col-auto">
          <base-button
            v-if="showCancelButton"
            @click="closeOrganizationFormModal"
            type="button"
            class="btn btn-sm btn-danger"
            :disabled="loading"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading" />
            {{ $t("COMMON.CLOSE") }}
          </base-button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import models from "@/constants/models";
import { phoneTypesOptions } from "@/constants/common";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    ResellerSelector,
    ImageSelector,
    BillingInformationsForm,
    [Select.name]: Select,
    [Option.name]: Option,
    PhoneNumberInput,
    PhoneNumbersSelector,
  },

  mixins: [formMixin],
  props: {
    organizationData: {},
    formErrors: {},
    loading: {},
    showCancelButton: {},
  },

  data() {
    return {
      organization: { ...this.organizationData },
      permissions: [],
      models: models,
      phoneTypesOptions,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      const organizationData = cloneDeep(this.organization);
      if (organizationData.owner) {
        if (organizationData.owner.id == null) {
          organizationData.owner = null;
        }
      }
      this.$emit("organizationSubmitted", organizationData);
    },

    billingInformationsChanged(billingInformations) {
      this.organization = { ...this.organization, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    closeOrganizationFormModal() {
      this.$emit("cancelOrganizationForm");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    organizationData(organizationData) {
      if (organizationData) {
        this.organization = {
          ...this.organization,
          ...cloneDeep(organizationData),
        };
      }
    },
  },
};
</script>
