<template>
  <div class="container-fluid">
    <div class="row flex justify-content-between mb-5">
      <div class="display-4 col-auto">{{ organization.name }}</div>

      <div
        class="col-auto"
        v-if="
          showEditButton &&
          $currentUserCan($permissions.PERM_EDIT_HIS_ORGANIZATIONS)
        "
      >
        <base-button
          @click="openOrganizationFormModal"
          type="button"
          class="btn btn-md btn-primary"
        >
          {{ $t("COMMON.EDIT") }}
        </base-button>
      </div>
    </div>

    <div class="row">
      <div class="col-9">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ organization.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="organization.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ organization.email }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd class="col-sm-8">
            {{
              organization.phone_type
                ? $t(`COMMON.PHONE_TYPE_${organization.phone_type}`)
                : null
            }}:
            {{ organization.phone }}
            {{
              organization.phone_extension
                ? ` (ext: ${organization.phone_extension})`
                : null
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd class="col-sm-8">
            <span
              v-for="(line, index) in organization.other_phones"
              :key="index"
            >
              {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
              {{ line.phoneNumber }}
              {{ line.extension ? ` (ext: ${line.extension})` : null }} <br />
            </span>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ organization.address }}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.RESELLER") }}</dt>
          <dd class="col-sm-8">
            <reseller :reseller="organization.reseller" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.OWNER") }}</dt>
          <dd class="col-sm-8">
            <router-link
              :to="{ name: 'View User', params: { id: organization.owner.id } }"
            >
              {{
                `${organization.owner.firstname} ${organization.owner.lastname} - ${organization.owner.email}`
              }}
            </router-link>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="organization.created_at">
            {{ $formatDate(organization.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="organization.updated_at">
            {{ $formatDate(organization.updated_at) }}
          </dd>
        </dl>

        <div class="col-12 text-center mb-2 mt-5">
          <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
        </div>

        <billing-informations-view :billingInformations="organization" />
      </div>
      <div class="col-3">
        <img
          v-if="organization.logo"
          :src="`${organization.logo}`"
          class="argon-image"
          style="width: 100%"
        />
        <img v-else src="/img/placeholder.jpg" class="argon-image" />
      </div>
    </div>
  </div>
</template>

<script>
import models from "@/constants/models";
import BillingInformationsView from "@/components/BillingInformationsView.vue";

export default {
  name: "organization-view-global",

  components: {
    BillingInformationsView,
  },

  propss: ["organization"],
  props: {
    organization: {
      default: null,
      type: Object,
    },
    showEditButton: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      models: models,
    };
  },

  computed: {},

  created() {},

  methods: {
    openOrganizationFormModal() {
      this.$emit("openOrganizationEditFormModal");
    },
  },

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
