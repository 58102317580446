<template>
  <div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.TYPE')} (*)`"
          :placeholder="`${$t('COMMON.TYPE')} (*)`"
        >
          <el-select
            name="Type"
            v-model="billingInformations.billing_entity_type"
            @change="billingCustomerTypeChanged"
          >
            <el-option
              :value="BILLING_ENTITY_TYPE_INDIVIDUAL"
              :label="$t('COMMON.BILLING_ENTITY_TYPE_INDIVIDUAL')"
            />
            <el-option
              :value="BILLING_ENTITY_TYPE_COMPANY"
              :label="$t('COMMON.BILLING_ENTITY_TYPE_COMPANY')"
            />
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.billing_entity_type" />
      </div>
    </div>

    <div
      class="row"
      v-if="
        billingInformations.billing_entity_type ==
        BILLING_ENTITY_TYPE_INDIVIDUAL
      "
    >
      <div class="col">
        <base-input
          :label="`${$t('COMMON.FIRSTNAME')} (*)`"
          v-model="billingInformations.billing_firstname"
          @change="billingInformationChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_firstname" />
      </div>
      <div class="col">
        <base-input
          :label="`${$t('COMMON.LASTNAME')} (*)`"
          v-model="billingInformations.billing_lastname"
        />
        <validation-error :errors="apiValidationErrors.billing_lastname" />
      </div>
    </div>

    <div
      class="row"
      v-if="
        billingInformations.billing_entity_type == BILLING_ENTITY_TYPE_COMPANY
      "
    >
      <div class="col">
        <base-input
          :label="`${$t('COMMON.COMPANY_NAME')} (*)`"
          v-model="billingInformations.billing_company_name"
          @change="billingInformationChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_company_name" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.EMAIL')} (*)`"
          v-model="billingInformations.billing_email"
          @change="billingInformationChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_email" />
      </div>
    </div>

    <div class="form-wrapper full mb-3">
      <addresses-selector
        selectorUniqueId="address-input-unique-2"
        @addressInputChangeChanged="
          (value) => {
            billingInformations = {
              ...billingInformations,
              billing_country: value.country,
              billing_state: value.state,
              billing_city: value.city,
              billing_city: value.city,
              billing_zipcode: value.zipcode,
              billing_address: value.address,
            };
            billingInformationChanged();
          }
        "
      />
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.COUNTRY')} (*)`"
          :placeholder="`${$t('COMMON.COUNTRY')} (*)`"
        >
          <country-selector
            :country="billingInformations.billing_country"
            :filterable="true"
            :showAll="false"
            @countryChanged="countryChanged"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.billing_country" />
      </div>
      <div class="col">
        <base-input
          :label="`${$t('COMMON.STATE')} (*)`"
          :placeholder="`${$t('COMMON.STATE')} (*)`"
        >
          <state-selector
            :country="billingInformations.billing_country"
            :state="billingInformations.billing_state"
            :filterable="true"
            :showAll="false"
            @stateChanged="stateChanged"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.billing_state" />
      </div>
      <div class="col">
        <base-input
          :label="`${$t('COMMON.CITY')} (*)`"
          v-model="billingInformations.billing_city"
          @change="billingInformationChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_city" />
      </div>
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ZIPCODE')} (*)`"
          v-model="billingInformations.billing_zipcode"
          @change="billingInformationChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_zipcode" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ADDRESS')} (*)`"
          v-model="billingInformations.billing_address"
          @change="billingInformationChanged"
        />
        <validation-error :errors="apiValidationErrors.billing_address" />
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import AddressesSelector from "@/components/AddressesSelector.vue";

export default {
  name: "billing-informations-form",

  components: {
    BaseInput,
    ValidationError,
    CountrySelector,
    StateSelector,
    AddressesSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["billingInformationsData", "formErrors"],

  data() {
    return {
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
      billingInformations: {
        billing_entity_type: BILLING_ENTITY_TYPE_INDIVIDUAL,
        billing_company_name: null,
        billing_email: null,
        billing_firstname: null,
        billing_lastname: null,
        billing_country: "Canada",
        billing_state: "Quebec",
        billing_city: null,
        billing_zipcode: null,
        billing_address: null,
        ...this.getBillingInformationsFromBillingInformationsData(
          this.billingInformationsData
        ),
      },
    };
  },

  created() {},

  methods: {
    billingInformationChanged() {
      this.$emit("onChangeBillingInformations", this.billingInformations);
    },
    countryChanged(country) {
      this.billingInformations.billing_country = country;
      this.billingInformationChanged();
    },
    stateChanged(state) {
      this.billingInformations.billing_state = state;
      this.billingInformationChanged();
    },
    getBillingInformationsFromBillingInformationsData(billingInformationsData) {
      const billingInformations = {};
      for (const key in billingInformationsData) {
        if (key.startsWith("billing_")) {
          billingInformations[key] = billingInformationsData[key];
        }
      }
      return billingInformations;
    },
    billingCustomerTypeChanged(type) {
      if (type === BILLING_ENTITY_TYPE_INDIVIDUAL) {
        this.billingInformations.billing_firstname = null;
        this.billingInformations.billing_lastname = null;
        this.billingInformations.billing_company_name = "N/A";
      } else if (type === BILLING_ENTITY_TYPE_COMPANY) {
        this.billingInformations.billing_company_name = null;
        this.billingInformations.billing_firstname = "N/A";
        this.billingInformations.billing_lastname = "N/A";
      }
      this.billingInformationChanged();
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    billingInformationsData(billingInformationsData) {
      if (billingInformationsData) {
        this.billingInformations = {
          ...this.billingInformations,
          ...this.getBillingInformationsFromBillingInformationsData(
            billingInformationsData
          ),
        };
      }
    },
  },
};
</script>
